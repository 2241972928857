import type { Numeric } from 'decimal.js-light';
import Decimal from 'decimal.js-light';

import type {
  InvoiceCalculationDetailsOutput,
  Maybe,
  PublicAssetOutput,
} from '@kuna-pay/core/generated/public/graphql';
import { AssetType } from '@kuna-pay/core/generated/public/graphql';
import { formatPercent } from '@kuna-pay/core/shared/lib/percent';

type FormatAsset = Pick<PublicAssetOutput, 'type' | 'precision'> &
  Partial<Pick<PublicAssetOutput, 'code'>>;
type FormatAssetConfig = {
  includeCode?: boolean;
  /**
   *  @see https://kunatech.atlassian.net/browse/KUPAY-1153
   */
  round?: 'up' | 'down' | 'default';

  /**
   * Locale for amount separator
   */
  locale?: string;
};
type FormatAmount = Numeric | null | undefined;

class AssetParser {
  public static parseAmount(amount: FormatAmount): Decimal {
    try {
      if (!amount) {
        amount = 0;
      }

      return new Decimal(amount);
    } catch (e) {
      console.error('Failed to parse amount', { amount }, e);

      return new Decimal(0);
    }
  }

  public static toParsedWithPrecision(
    input: FormatAmount,
    asset?: Maybe<FormatAsset>,
    config?: Pick<FormatAssetConfig, 'round'>
  ): string {
    asset ??= { type: AssetType.Fiat, precision: 2 };
    config ??= { round: 'default' };

    return AssetParser.parseAmount(input).toFixed(
      asset.precision,
      this.getRoundMode(config.round)
    );
  }

  private static getRoundMode(round: FormatAssetConfig['round']) {
    switch (round) {
      case 'up':
        return Decimal.ROUND_UP;
      case 'down':
        return Decimal.ROUND_DOWN;
      default:
        return undefined;
    }
  }
}

class AssetFormat {
  private static attachCode(
    str: string,
    asset: FormatAsset,
    config: FormatAssetConfig = { includeCode: true }
  ): string {
    const shouldIncludeCode = config.includeCode && !!asset?.code;

    if (shouldIncludeCode) {
      str += ` ${asset.code}`;
    }

    return str;
  }

  private static prettifyInt(str: string, separator = ' '): string {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, separator).replaceAll(' ', ' ');
  }

  private static transformAmount(
    amount: string,
    locale: string,
    defaultDecimal?: string
  ): string {
    const [int = '0', decimal = defaultDecimal] = amount.split('.');

    return [
      this.prettifyInt(int, AssetFormat.thousandsSeparator(locale)),
      decimal,
    ]
      .filter(Boolean)
      .join(AssetFormat.decimalSeparator(locale));
  }

  private static trimTrailingZerosFromDecimals(amount: string) {
    try {
      let [int, decimals] = amount.split('.');

      int ??= '0';
      decimals ??= '';

      return `${int}.${decimals.split('').reduceRight((acc, digit) => {
        if (acc.length !== 0 || digit !== '0') {
          return digit + acc;
        }

        return acc;
      }, '')}`;
    } catch {
      return amount;
    }
  }

  /**
   * @example '0.00000000 BTC'
   * @example '0.50000000 BTC'
   * @example '0.00 UAH'
   * @example '100.50 UAH'
   * @example '1 000.50 UAH'
   */
  public static formatBalance(
    input: FormatAmount,
    asset: Maybe<FormatAsset>,
    config: Maybe<FormatAssetConfig>
  ): string {
    asset ??= { type: AssetType.Fiat, precision: 2 };
    config ??= { includeCode: true };
    config.round ??= 'default';
    config.includeCode ??= true;
    config.locale ??= 'en';

    return this.attachCode(
      this.transformAmount(
        AssetParser.parseAmount(input).toFixed(asset.precision),
        config.locale,
        '0'.repeat(asset.precision)
      ),
      asset,
      config
    );
  }

  /**
   * @description Use this formatter for on-change input handlers
   *
   * @example '0 BTC'
   * @example '0.5 BTC'
   * @example '0 UAH'
   * @example '100.5 UAH'
   * @example '1 000 UAH'
   */
  public static formatEnterInputAmount(
    input: FormatAmount,
    asset?: Maybe<FormatAsset>,
    config?: FormatAssetConfig
  ): string {
    asset ??= { type: AssetType.Fiat, precision: 2 };
    config ??= { includeCode: true, round: 'default' };

    config.round ??= 'default';
    config.includeCode ??= true;
    config.locale ??= 'en';

    return this.attachCode(
      this.transformAmount(
        AssetParser.parseAmount(input).toSignificantDigits().toString(),
        config.locale
      ),
      asset,
      config
    );
  }

  /**
   * @example '0 BTC'
   * @example '0.5 BTC'
   * @example '0.00 UAH'
   * @example '100.50 UAH'
   * @example '1 000.00 UAH'
   */
  public static formatAmount(
    input: FormatAmount,
    asset?: Maybe<FormatAsset>,
    config?: FormatAssetConfig
  ): string {
    asset ??= { type: AssetType.Fiat, precision: 2 };
    config ??= { includeCode: true, round: 'default' };
    config.round ??= 'default';
    config.includeCode ??= true;
    config.locale ??= 'en';

    const parsedAmount = AssetParser.toParsedWithPrecision(
      input,
      asset,
      config
    );

    return this.attachCode(
      this.transformAmount(
        asset.type === AssetType.Fiat
          ? parsedAmount
          : this.trimTrailingZerosFromDecimals(parsedAmount),
        config.locale
      ),
      asset,
      config
    );
  }

  /**
   * @example '0 BTC = 0.00 UAH'
   * @example '0.69 BTC = 100.50 UAH'
   * @example '1 BTC = 889 105.36 UAH'
   */
  public static formatExchangeRate(
    from: FormatAsset,
    rate: FormatAmount,
    to?: FormatAsset,
    config?: {
      from: FormatAssetConfig;
      to: FormatAssetConfig;
    }
  ): string {
    const fromAmount = this.formatAmount(1, from, config?.from);
    const toAmount = this.formatAmount(rate, to, config?.to);

    return `${fromAmount} ≈ ${toAmount}`;
  }

  public static formatExchangeRateWithAppliedCoercionCoefficient(
    from: FormatAsset,
    rate: FormatAmount,
    to: FormatAsset,
    CalculationDetails: InvoiceCalculationDetailsOutput,
    config: {
      from: FormatAssetConfig;
      to: FormatAssetConfig;
    }
  ): string {
    const fromAmount = this.formatAmount(1, from, config.from);

    const toAmount = this.formatAmount(rate, to, config.to);

    const coefficient = formatPercent(
      CalculationDetails.withCoefficient.coefficient
    );

    return `${fromAmount} + ${coefficient} ≈ ${toAmount}`;
  }

  /**
   * @param locale {string}
   * @returns {string}
   *
   * @example 'en' => '.'
   * @example 'uk' => ','
   * @example 'de' => ','
   * @example 'it' => ','
   * @example 'pl' => ','
   * @example 'pt' => ','
   * @example 'es' => ','
   *
   */
  public static decimalSeparator = (locale: string) => {
    try {
      const number = 1.1;
      const formatter = new Intl.NumberFormat(locale);
      const result = formatter.format(number);

      return result.slice(1, 2);
    } catch {
      return '.';
    }
  };

  /**
   * @param locale {string}
   * @returns {string}
   *
   * @example 'en' => ','
   * @example 'uk' => ' '
   * @example 'de' => '.'
   * @example 'it' => '.'
   * @example 'pl' => ' '
   * @example 'pt' => '.'
   * @example 'es' => '.'
   *
   */
  public static thousandsSeparator = (locale: string) => {
    try {
      const number = 1_000_000;
      const formatter = new Intl.NumberFormat(locale);
      const result = formatter.format(number);

      return result.slice(1, 2);
    } catch {
      return ' ';
    }
  };
}

export type { FormatAmount, FormatAsset, FormatAssetConfig };
export { AssetFormat, AssetParser };
