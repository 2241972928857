import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { CopyButtonProps } from '@kuna-pay/ui/ui/button';
import { CopyButton } from '@kuna-pay/ui/ui/button';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { Accordion } from '@kuna-pay/accept-payment/shared/ui/accordion';

import styles from './accordion-details.module.scss';

type ProductDetailsProps = PropsWithChildren & {
  title: string;
  className?: string;

  defaultOpen?: boolean;
};

const AccordionDetails = createCompoundComponent(
  () =>
    ({ className, title, children, defaultOpen }: ProductDetailsProps) => (
      <Accordion
        classes={{
          root: clsx(styles.root, className),
          content: styles.content,
        }}
        title={title}
        defaultOpen={defaultOpen}
      >
        {children}
      </Accordion>
    ),
  {
    Column: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.column, className)} {...props} />
    ),

    Row: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.row, className)} {...props} />
    ),

    Label: ({
      className,
      children,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => (
      <Typography
        className={clsx(styles.label, className)}
        variant='numbers2'
        {...props}
      >
        {children}:
      </Typography>
    ),

    Value: (props: PartialField<TypographyProps, 'variant'>) => (
      <Typography variant='numbers1' {...props} />
    ),

    Empty: ({
      className,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => (
      <Typography
        variant='numbers1'
        className={clsx(styles.empty, className)}
        {...props}
      >
        -
      </Typography>
    ),

    IdValue: ({
      className,
      value,
      customMessage,
      classes,
      ...props
    }: PartialField<TypographyProps, 'variant'> & CopyButtonProps) => (
      <CopyButton classes={classes} value={value} customMessage={customMessage}>
        <Typography
          className={clsx(styles.value, styles.id, className)}
          variant='numbers1'
          nowrap
          {...props}
        />
      </CopyButton>
    ),

    Divider: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.divider, className)} {...props} />
    ),
  }
);

export { AccordionDetails };
