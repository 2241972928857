import * as Sentry from '@sentry/react';
import type { Store } from 'effector';
import { attach, createEffect } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';
import type { IGraphqlClientWithMeta } from '@kuna-pay/core/shared/api/http';

import {
  ApiMonitoringIntegration,
  FeatureFlagsMonitoringIntegration,
} from './integrations';

type MonitoringConfig = {
  dsn: string;
  environment: string;
  release: string;

  /**
   * Feature flags with which application is running
   * (to show in Sentry)
   */
  featureFlags: {
    $features: Store<Record<string, boolean>>;
  };

  api: {
    gwUrl?: string;

    graphqlClient: () => IGraphqlClientWithMeta;

    filteredErrorCodes?: string[];
  };

  // router: {
  //   history: History;
  // };
};

const Monitoring = modelFactory((config: MonitoringConfig) => {
  const initFx = attach({
    source: {
      featureFlags: config.featureFlags.$features,
    },
    effect: async ({ featureFlags }) => {
      /**
       * Disable sentry in development
       */
      if (!import.meta.env.PROD) {
        return [];
      }

      const sentryConfig: Sentry.BrowserOptions = {
        dsn: config.dsn,
        environment: config.environment,
        release: config.release,
        integrations: config.api.gwUrl
          ? [
              Sentry.replayIntegration({
                networkDetailAllowUrls: [config.api.gwUrl],
              }),
            ]
          : [Sentry.replayIntegration()],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      };

      Sentry.init(sentryConfig);

      const integrations = [
        new FeatureFlagsMonitoringIntegration(featureFlags),

        new ApiMonitoringIntegration(config.api.graphqlClient(), {
          filterErrorCodes: config.api.filteredErrorCodes ?? [],
        }),

        // TODO(Router)
        // new RouterMonitoringIntegration(config.router.history)
      ];

      integrations.forEach((integration) => {
        integration.install();
      });
    },
  });

  const identifyFx = createEffect((id: string | null) => {
    if (id) {
      Sentry.addBreadcrumb({
        type: 'auth',
        category: 'authentication',
        message: 'User logged in',
        timestamp: Date.now(),
        data: { id },
        level: 'info',
      });
      Sentry.setUser({ id });
    } else {
      Sentry.addBreadcrumb({
        type: 'auth',
        category: 'authentication',
        message: 'User logged out',
        timestamp: Date.now(),
        level: 'info',
      });
      Sentry.setUser(null);
    }
  });

  const setTagFx = createEffect(
    ({ key, value }: { key: string; value: SentryPrimitive }) => {
      Sentry.setTag(key, value);
    }
  );

  return { initFx, identify: identifyFx, setTag: setTagFx };
});

type SentryPrimitive =
  | number
  | string
  | boolean
  | bigint
  | symbol
  | null
  | undefined;

export { Monitoring };
