import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetIcon, DropdownIcon } from '@kuna-pay/ui/icons';
import { BaseButton } from '@kuna-pay/ui/ui/button';
import { Tag } from '@kuna-pay/ui/ui/tag';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { PaymentMethodSyncSelectModel } from './sync-select.model';
import styles from './select-payment-method.module.scss';

type SelectPaymentMethodButtonProps = {
  $$model: PaymentMethodSyncSelectModel;
};

const SelectPaymentMethodButton = memo(
  ({ $$model }: SelectPaymentMethodButtonProps) => {
    const { t } = useTranslation();

    const [paymentMethod, onClick] = useUnit([
      $$model.$value,
      $$model.buttonClicked,
    ]);

    return (
      <BaseButton className={styles.button} onClick={onClick}>
        {paymentMethod?.icon ? (
          <img
            className={styles.buttonAssetIcon}
            src={paymentMethod.icon}
            alt=''
          />
        ) : (
          <AssetIcon className={styles.buttonAssetIcon} />
        )}

        <span className={styles.buttonContent}>
          {!paymentMethod ? (
            <Typography className={styles.buttonText} variant='subtitle5'>
              {t(
                'pages.checkout.choose-payment-method.select-currency.button.text'
              )}
            </Typography>
          ) : (
            <>
              <Typography className={styles.buttonText} variant='subtitle5'>
                {paymentMethod.asset}
              </Typography>

              {!!paymentMethod.network && (
                <Tag className={styles.buttonContentTag} variant='common'>
                  {paymentMethod.network}
                </Tag>
              )}
            </>
          )}
        </span>

        <DropdownIcon className={styles.buttonDropdownIcon} />
      </BaseButton>
    );
  }
);

export type { SelectPaymentMethodButtonProps };
export { SelectPaymentMethodButton };
