import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TextFieldProps } from '@kuna-pay/ui/ui/text-field';
import { InputAdornment, TextField } from '@kuna-pay/ui/ui/text-field';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { useAssetFormat } from '../../lib';
import type { CoreAsset } from '../../types';
import styles from '././amount-input.module.scss';

type AmountInputProps = Pick<
  TextFieldProps,
  | 'isError'
  | 'helperText'
  | 'size'
  | 'variant'
  | 'label'
  | 'name'
  | 'placeholder'
  | 'disabled'
  | 'onFocus'
  | 'onBlur'
> & {
  asset?: Pick<CoreAsset, 'code' | 'precision'> | null;

  onValueChange?: (value: NumberFormatValues) => void;

  onChange?: (event: { target: { value: string } }) => void;

  value: string;

  name: string;

  end?: ReactNode;
};

const AmountInput = createCompoundComponent(
  (Components) =>
    ({ asset, value, placeholder, end, ...props }: AmountInputProps) => {
      const AssetFormat = useAssetFormat();
      const precision = asset ? asset.precision : 2;

      return (
        <NumericFormat
          value={value}
          allowNegative={false}
          decimalSeparator={AssetFormat.decimalSeparator()}
          decimalScale={precision}
          thousandSeparator={AssetFormat.thousandsSeparator()}
          valueIsNumericString
          customInput={TextField}
          placeholder={
            asset
              ? getPlaceholder(precision, {
                  decimalSeparator: AssetFormat.decimalSeparator,
                })
              : (placeholder ??
                getPlaceholder(precision, {
                  decimalSeparator: AssetFormat.decimalSeparator,
                }))
          }
          end={
            <InputAdornment>
              {end ?? <Components.End code={asset?.code} />}
            </InputAdornment>
          }
          {...props}
        />
      );
    },
  {
    End: ({
      code,
      className,
      ...props
    }: Partial<Omit<TypographyProps, 'children'>> & { code?: string }) => (
      <>
        {!!code && (
          <Typography
            className={clsx(styles.assetCode, className)}
            variant='subtitle5'
            {...props}
          >
            {code}
          </Typography>
        )}
      </>
    ),
  }
);

function getPlaceholder(
  assetPrecision: number,
  {
    decimalSeparator,
  }: {
    decimalSeparator: () => string;
  }
) {
  const separator = decimalSeparator();

  return `0${separator}${'0'.repeat(assetPrecision)}`;
}

export { AmountInput };
export type { AmountInputProps };
