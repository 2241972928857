import { createInstance, Identify } from '@amplitude/analytics-browser';
import type { BrowserOptions, EventOptions } from '@amplitude/analytics-types';
import { attach, createStore } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';

type AmplitudeAnalyticsFactoryConfig = {
  API_KEY: string;

  initOptions?: BrowserOptions;
  eventOptions?: EventOptions;
};

const AmplitudeAnalyticsFactory = modelFactory(
  <
    TEvents extends {
      event: string;
      properties?: Record<string, unknown>;
      options?: EventOptions;
    },
  >(
    config: AmplitudeAnalyticsFactoryConfig
  ) => {
    config.eventOptions ??= {};

    const $instance = createStore(createInstance());

    const initFx = attach({
      source: $instance,
      effect: async (instance, options: BrowserOptions) => {
        instance.init(config.API_KEY, { ...config.initOptions, ...options });

        /**
         * @see https://www.docs.developers.amplitude.com/data/sdks/browser-2/#set-the-transport-to-use-beacon-only-when-exiting-page
         */
        window.addEventListener('pagehide', () => {
          instance.setTransport('beacon');
          // Sets https transport to use `sendBeacon` API
          instance.flush();
        });
      },
    });

    const logEventFx = attach({
      source: $instance,
      effect: async (instance, event: TEvents) => {
        instance.logEvent(event.event, event.properties, {
          ...config.eventOptions,
          ...event.options,
        });
      },
    });

    const identifyFx = attach({
      source: $instance,
      effect: async (instance, userId: string | null) => {
        if (!userId) {
          instance.setUserId(undefined);
        } else {
          instance.setUserId(userId);
        }
      },
    });

    const updateIdentityFx = attach({
      source: $instance,
      effect: async (instance, options: EventOptions) => {
        instance.identify(new Identify(), options);
      },
    });

    return {
      $instance,
      initFx,
      logEvent: logEventFx,
      identify: identifyFx,
      updateIdentity: updateIdentityFx,
    };
  }
);

type IAmplitudeAnalyticsModel<
  TEvents extends {
    event: string;
    properties?: Record<string, unknown>;
    options?: EventOptions;
  } = any,
> = ReturnType<typeof AmplitudeAnalyticsFactory.createModel<TEvents>>;

export { AmplitudeAnalyticsFactory };
export type { IAmplitudeAnalyticsModel };
