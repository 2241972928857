import {
  SuccessOutput,
  MutationSendInvoiceReceiptToBuyerArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const sendInvoiceReceiptToBuyerRequest = (
  select: Select<SuccessOutput> | string
) => `mutation sendInvoiceReceiptToBuyer($email: String!, $id: String!, $language: String) {
  data: sendInvoiceReceiptToBuyer(email: $email, id: $id, language: $language) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const sendInvoiceReceiptToBuyer =
  (select: Select<SuccessOutput> | string) =>
  (args: MutationSendInvoiceReceiptToBuyerArgs) =>
    query<SuccessOutput>(sendInvoiceReceiptToBuyerRequest(select), args);
