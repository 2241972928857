import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { MerchantAvatar } from '@kuna-pay/core/entities/merchant';

import {
  SelectPaymentMethod,
  SelectPaymentMethodButton,
} from '@kuna-pay/accept-payment/entities/payment-method';

import { CheckoutPageModel } from '../../page.model';
import { CheckoutProgressTracker } from '../shared';
import { InvoiceAmount } from './invoice-amount';
import { InvoiceDetails } from './invoice-details';
import { ProductDetails } from './product-details';
import styles from './choose-payment-method.module.scss';

type ChoosePaymentMethodViewProps = PropsWithChildren & {
  className?: string;
};

const ChoosePaymentMethodView = memo(
  ({ className, children }: ChoosePaymentMethodViewProps) => {
    const $$model = CheckoutPageModel.useModel();
    const { Company } = useUnit($$model.$invoice)!;

    const { t } = useTranslation();

    return (
      <SelectPaymentMethod
        prepend={<CheckoutProgressTracker className={styles.tracker} />}
        className={className}
        $$model={$$model.$$chosePaymentMethod.$$selectPaymentMethod}
      >
        <CheckoutProgressTracker />

        <Paper className={clsx(styles.root, className)}>
          <MerchantAvatar className={styles.avatar} src={Company.avatar} />

          <Typography className={styles.title} variant='subtitle3'>
            {Company.title}
          </Typography>

          <Typography className={styles.subtitle} variant='h7'>
            {t('pages.checkout.choose-payment-method.description')}
          </Typography>

          <SelectPaymentMethodButton
            $$model={$$model.$$chosePaymentMethod.$$selectPaymentMethod}
          />

          <InvoiceAmount className={styles.invoiceAmount} />

          <InvoiceDetails className={styles.invoiceDetails} />

          <ProductDetails className={styles.productDetails} />

          <div className={styles.spacer} />

          <SubmitButton />

          {children}

          <div className={styles.submitSpacer} />
        </Paper>
      </SelectPaymentMethod>
    );
  }
);

const SubmitButton = memo(() => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation();

  const [
    selectedPaymentMethod,
    isInvalid,
    loading,
    onSubmit,
    paymentCostHigherThanFee,
  ] = useUnit([
    $$model.$$chosePaymentMethod.$$selectPaymentMethod.$value,
    $$model.$$chosePaymentMethod.$isInvalid,
    $$model.$$chosePaymentMethod.$submitting,
    $$model.$$chosePaymentMethod.submitted,
    $$model.$$chosePaymentMethod.$paymentCostHigherThanFee,
  ]);

  return (
    <div className={styles.submit}>
      <Button
        variant='contained'
        color='primary'
        size='xl'
        onClick={onSubmit}
        disabled={isInvalid || paymentCostHigherThanFee.isOutOfLimit}
        loading={loading}
        fullWidth
      >
        {selectedPaymentMethod
          ? t('pages.checkout.choose-payment-method.submit.selected', {
              replace: { asset: selectedPaymentMethod.asset },
            })
          : t('pages.checkout.choose-payment-method.submit.initial')}
      </Button>
    </div>
  );
});

export { ChoosePaymentMethodView };
