import clsx from 'clsx';
import { useStoreMap, useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import { cloneElement, forwardRef, isValidElement, useContext } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { useScrollToSelectedElement } from '@kuna-pay/ui/components/select/lib';
import type { IconProps } from '@kuna-pay/ui/icons';
import { CheckIcon } from '@kuna-pay/ui/icons';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';

import { SingleSelectModel } from '../../model';
import type { ItemOptionProps } from './option.core';
import { CheckedContext, Option } from './option.core';
import styles from './option.module.scss';

const SingleSelectCheckboxOption = createCompoundComponent(
  ({ Root, Indicator, Text }) =>
    ({
      option,
      formatOption,
      color = 'primary',
      scrollToSelectedItemWhenOpen,
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof Text>, 'children'> & {
      option: string;

      formatOption?: (option: string) => string;

      // TODO: make this common for all selects
      scrollToSelectedItemWhenOpen?: boolean;
    }) => (
      <Root
        option={option}
        scrollToSelectedItemWhenOpen={scrollToSelectedItemWhenOpen}
      >
        {({ option }) => {
          const formattedOption = formatOption ? formatOption(option) : option;

          return (
            <>
              <Text title={formattedOption} color={color} {...props}>
                {formattedOption}
              </Text>

              {color === 'primary' && <Indicator />}
            </>
          );
        }}
      </Root>
    ),

  {
    Root: forwardRef<
      HTMLButtonElement,
      ItemOptionProps & {
        // TODO: make this common for all selects
        scrollToSelectedItemWhenOpen?: boolean;
      }
    >(
      (
        { option, children, scrollToSelectedItemWhenOpen, ...props },
        forwardedRef
      ) => {
        const { $$value } = SingleSelectModel.useModel();
        const [onValueChange] = useUnit([$$value.change]);

        const checked = useStoreMap({
          keys: [option],
          store: $$value.$value,
          fn: (value, [option]) => value === option,
        });

        const ref = useScrollToSelectedElement(
          forwardedRef,
          () => checked && !!scrollToSelectedItemWhenOpen
        );

        const onClick = () => {
          onValueChange(option);
        };

        return (
          <Option.Item ref={ref} onClick={onClick} {...props}>
            <CheckedContext.Provider value={checked}>
              {typeof children === 'function'
                ? children({ option, checked })
                : isValidElement(children)
                  ? cloneElement<any>(children, { 'data-checked': checked })
                  : children}
            </CheckedContext.Provider>
          </Option.Item>
        );
      }
    ),

    Text: forwardRef<
      HTMLSpanElement,
      TypographyProps & {
        color?: 'primary' | 'grey';
      }
    >(({ className, color = 'primary', ...props }, ref) => {
      const checked = useContext(CheckedContext);

      return (
        <Option.Text
          ref={ref}
          className={clsx(className, {
            [styles.checked]: checked,
            [styles.primary]: color === 'primary',
            [styles.grey]: color === 'grey',
          })}
          nowrap
          {...props}
        />
      );
    }),

    Indicator: forwardRef<SVGSVGElement, IconProps>(
      ({ className, ...props }, ref) => {
        const checked = useContext(CheckedContext);

        return (
          <>
            {checked && (
              <CheckIcon
                className={clsx(className, checked && 'text-deepblue500')}
                ref={ref}
                {...props}
              />
            )}
          </>
        );
      }
    ),
  }
);

export { SingleSelectCheckboxOption };
