import { useUnit } from 'effector-react';
import type {
  ChangeEvent,
  ComponentPropsWithoutRef,
  PropsWithChildren,
} from 'react';
import { forwardRef, useCallback } from 'react';

import { modelView } from '@kuna-pay/utils/effector';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { ControlInputRootProps } from '@kuna-pay/ui/components/control';
import { Search } from '@kuna-pay/ui/components/input/preset/search';

import { SelectSearchFactory } from '../../model';
import styles from './search.module.scss';

type SelectSearchInputProps = Omit<
  ComponentPropsWithoutRef<typeof Search.Input>,
  'className' | 'value' | 'onChange' | 'children' | 'color'
>;

type SelectSearchRootProps = Omit<ControlInputRootProps, 'state'>;

type SelectSearchProps = SelectSearchInputProps &
  Pick<SelectSearchRootProps, 'variant' | 'color'>;

const SelectSearch = createCompoundComponent(
  (Components) =>
    ({
      size = 'md',
      variant = 'contained',
      color = 'grey',
      ...props
    }: SelectSearchProps) => (
      <Components.Root size={size} variant={variant} color={color}>
        <Components.Start size={size} color={color} />

        <Components.Content size={size} hasStartAdornment>
          <Components.Input size={size} placeholder='Seach' {...props} />
        </Components.Content>
      </Components.Root>
    ),
  {
    Provider: modelView(
      SelectSearchFactory,
      ({ children }: PropsWithChildren) => <>{children}</>
    ),

    Root: forwardRef<HTMLDivElement, SelectSearchRootProps>((props, ref) => (
      <div className={styles.root}>
        <Search.Root ref={ref} state='idle' {...props} />
      </div>
    )),

    Input: forwardRef<HTMLInputElement, SelectSearchInputProps>(
      (props, ref) => {
        const $$search = SelectSearchFactory.useModel();
        const [value, onQueryChange] = useUnit([
          $$search.$query,
          $$search.change,
        ]);

        const onChange = useCallback(
          (event: ChangeEvent<HTMLInputElement>) => {
            onQueryChange(event.target.value);
          },
          [onQueryChange]
        );

        return (
          <Search.Input
            ref={ref}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      }
    ),

    Start: Search.Start,

    Content: Search.Content,
  }
);

export { SelectSearch };
