import type { PublicInvoiceDetailsOutput } from '../../api';
import { NonQuestionnaireProgressState } from './non-questionnaire.progress.state';
import type { IProgressState } from './progress.types';

class ProgressQuestionnaireState extends NonQuestionnaireProgressState {
  public override created(invoice: PublicInvoiceDetailsOutput): IProgressState {
    if (invoice.questionnaire) {
      if (invoice.questionnaire.Details?.isFilled) {
        const state = super.created(invoice);

        return {
          details: 'completed',
          init: state.init,
          payment: state.payment,
          done: state.done,
        };
      }

      return {
        details: 'active',
        init: 'inactive',
        payment: 'inactive',
        done: 'inactive',
      };
    }

    return super.created(invoice);
  }

  public override confirmationAwaiting(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(
      super.confirmationAwaiting(invoice),
      invoice
    );
  }

  public override limitsOutOfRange(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(
      super.limitsOutOfRange(invoice),
      invoice
    );
  }

  public override paymentAwaiting(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(
      super.paymentAwaiting(invoice),
      invoice
    );
  }

  /**
   * @note For statuses below we don't show progress bar
   */
  public override paid(invoice: PublicInvoiceDetailsOutput): IProgressState {
    return this.attachQuestionnaireState(super.paid(invoice), invoice);
  }

  public override partiallyPaid(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(super.partiallyPaid(invoice), invoice);
  }

  public override timeout(invoice: PublicInvoiceDetailsOutput): IProgressState {
    return this.attachQuestionnaireState(super.timeout(invoice), invoice);
  }

  public override deactivated(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(super.deactivated(invoice), invoice);
  }

  public override declined(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(super.declined(invoice), invoice);
  }

  public override suspended(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(super.suspended(invoice), invoice);
  }

  public override arrested(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(super.arrested(invoice), invoice);
  }

  public override currencyMismatch(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return this.attachQuestionnaireState(
      super.currencyMismatch(invoice),
      invoice
    );
  }

  public override unknown(invoice: PublicInvoiceDetailsOutput): IProgressState {
    return this.attachQuestionnaireState(super.unknown(invoice), invoice);
  }

  private attachQuestionnaireState(
    state: IProgressState,
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    if (invoice.questionnaire) {
      return {
        ...state,
        details: invoice.questionnaire?.Details?.isFilled
          ? 'completed'
          : 'inactive',
      };
    }

    return state;
  }
}

export { ProgressQuestionnaireState };
