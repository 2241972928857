import { captureException, captureMessage, withScope } from '@sentry/react';

import type {
  ErrorWithMeta,
  IGraphqlClientWithMeta,
} from '@kuna-pay/core/shared/api/http';

import { MonitoringApiRequestKind, MonitoringErrorType } from '../../config';
import { BaseTrackingIntegration } from '../core.integration';
import {
  CustomError,
  InternalServerError,
  RBACError,
  UnknownApiError,
  UnparsedApiError,
} from './api.exceptions';
import type { ApiErrorParseResult } from './api.types';

class ApiMonitoringIntegration extends BaseTrackingIntegration {
  public constructor(
    private readonly client: IGraphqlClientWithMeta,
    private readonly config: {
      filterErrorCodes: string[];
    } = {
      filterErrorCodes: [],
    }
  ) {
    super();
  }

  public override install = () => {
    this.installErrorTracking();
  };

  private readonly installErrorTracking = () => {
    const subscription = this.client
      .onErrorWithMeta()
      .subscribe(({ error, request: { query } }) => {
        withScope((scope) => {
          try {
            scope.setTag('type', MonitoringErrorType.API);

            scope.setTag('request', this.getQueryNameFromQuery(query));
            scope.setTag(
              'request.kind',
              query.startsWith('mutation')
                ? MonitoringApiRequestKind.Mutation
                : MonitoringApiRequestKind.Query
            );

            scope.setExtra('request.query', this.sanitize(query));
            scope.setTag(
              'request.network',
              navigator.onLine ? 'online' : 'offline'
            );

            const result = this.parseError(error);

            scope.setExtra('error', result.error);

            this.captureApiException(result);
          } catch (e) {
            console.error('Failed to capture graphql error');
            captureException(e);
          }
        });
      });

    this.subscriptions.push(subscription);
  };

  private readonly captureApiException = (result: ApiErrorParseResult) => {
    switch (result.type) {
      case 'exception': {
        captureException(result.error);

        return;
      }

      case 'message': {
        captureMessage(result.message);

        return;
      }

      case 'unknown': {
        captureException(new UnknownApiError(result.message));

        return;
      }

      case 'custom-exception': {
        if (this.config.filterErrorCodes.includes(result.code)) {
          return;
        }

        switch (true) {
          case RBACError.match(result): {
            captureException(new RBACError(result.message));

            return;
          }

          case InternalServerError.match(result): {
            captureException(new InternalServerError(result.message));

            return;
          }

          default: {
            captureException(new CustomError(result.code, result.message));

            return;
          }
        }
      }

      default: {
        /**
         * Should never happen but just in case
         */
        const _: never = result;
        console.error('Unknown error type', result);
        captureException(new UnparsedApiError());
      }
    }
  };

  private readonly parseError = (error: ErrorWithMeta): ApiErrorParseResult => {
    if (error instanceof Error) {
      return { type: 'exception', error } as const;
    }

    if (typeof error === 'string') {
      return { type: 'message', error, message: error } as const;
    }

    const firstError = error?.[0];

    if (typeof firstError === 'string') {
      return {
        type: 'message',
        error: firstError,
        message: firstError,
      } as const;
    }

    if (!firstError) {
      return { type: 'unknown', error } as const;
    }

    return {
      type: 'custom-exception',
      error: firstError,
      message: firstError.message,
      code: firstError.code,
    } as const;
  };

  private readonly getQueryNameFromQuery = (query: string) => {
    try {
      const [_kind, nameAndRest] = query.split(' ');

      if (!nameAndRest) {
        return 'UnknownQueryName';
      }
      const [name] = nameAndRest.split('(');

      if (!name) {
        return 'UnknownQueryName';
      }

      return name;
    } catch {
      return 'UnknownQueryName';
    }
  };
}

export { ApiMonitoringIntegration };
