import type { ModelOf } from '@kuna-pay/utils/effector';
import { StoreProperty } from '@kuna-pay/utils/effector/property';
import { NoOpKunaPayAuthService } from '@kuna-pay/core/shared/api/http';

import type { RecaptchaModel } from './recaptcha.model';

class APIRecaptchaIntegration extends NoOpKunaPayAuthService {
  public static createAuthServiceForGQLClient = (
    $recaptcha: ModelOf<typeof RecaptchaModel>
  ) => new APIRecaptchaIntegration($recaptcha);

  private static readonly RECAPTCHA_HEADER = 'recaptcha';

  private readonly $token: StoreProperty<string | null>;

  public constructor($recaptcha: ModelOf<typeof RecaptchaModel>) {
    super();
    this.$token = StoreProperty.from($recaptcha.$token);
  }

  public override addHeaders(headers: Record<string, unknown>) {
    const token = this.$token.value;

    if (token) {
      headers[APIRecaptchaIntegration.RECAPTCHA_HEADER] = token;
    }
  }
}

export { APIRecaptchaIntegration };
