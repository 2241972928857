import type { FC, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import { UIProvider } from '@kuna-pay/ui/provider';

import { i18next } from '@kuna-pay/accept-payment/shared/i18n';

const Provider: FC<PropsWithChildren> = ({ children }) => (
  <I18nextProvider i18n={i18next}>
    <UIProvider position='top-right'>{children}</UIProvider>
  </I18nextProvider>
);

const withProvider =
  <T extends Record<string, unknown>>(
    Component: FC<PropsWithChildren>
  ): FC<T> =>
  (props: T) => (
    <Provider>
      <Component {...props} />
    </Provider>
  );

export { Provider, withProvider };
