import clsx from 'clsx';
import type { HTMLAttributes, ImgHTMLAttributes } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { CoreAsset } from '../../types';
import styles from './currency.module.scss';

type CurrencyProps = {
  className?: string;

  asset: CoreAsset;

  imgSize?: number;

  showCode?: boolean;

  variant?: 'code' | 'name';

  typographyVariant?: TypographyProps['variant'];

  hideIcon?: boolean;
};

type CurrencyIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'width' | 'height'
> & {
  asset: CoreAsset;
  size?: number;
};

type CurrencyAssetCodeProps = Partial<TypographyProps> & {
  asset: CoreAsset;
  viewVariant?: 'code' | 'name';
  showCode?: boolean;

  classes?: {
    title?: string;
    code?: string;
  };
};

const Currency = createCompoundComponent(
  (Components) =>
    ({
      asset,
      imgSize,
      variant,
      showCode,
      className,
      typographyVariant,
      hideIcon,
      ...props
    }: CurrencyProps) => (
      <Components.Root className={className} {...props}>
        {!hideIcon && <Components.Icon asset={asset} size={imgSize} />}

        <Components.AssetCode
          asset={asset}
          viewVariant={variant}
          variant={typographyVariant}
          showCode={showCode}
        />
      </Components.Root>
    ),
  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),
    Icon: ({ asset, size = 24, ...props }: CurrencyIconProps) => {
      const icon = asset?.payload?.icons?.svg;

      if (!icon)
        return <div style={{ width: size, height: size }} aria-hidden />;

      return <img src={icon} alt='' width={size} height={size} {...props} />;
    },

    AssetCode: ({
      asset,
      className,
      showCode,
      viewVariant = 'name',
      variant = 'numberAdmin2',
      classes,
      ...props
    }: CurrencyAssetCodeProps) => {
      switch (viewVariant) {
        case 'name': {
          return (
            <>
              <Typography
                className={clsx(styles.title, classes?.title, className)}
                variant={variant}
                title={asset.name}
                {...props}
              >
                {asset.name}
              </Typography>

              {!!showCode && (
                <Typography
                  className={clsx(styles.code, classes?.code, className)}
                  variant={variant}
                  title={asset.code}
                  {...props}
                >
                  {asset.code}
                </Typography>
              )}
            </>
          );
        }

        case 'code': {
          return (
            <Typography
              className={clsx(styles.title, className)}
              variant={variant}
              title={asset.code}
              {...props}
            >
              {asset.code}
            </Typography>
          );
        }

        default: {
          return null;
        }
      }
    },
  }
);

export { Currency };
