import Decimal from 'decimal.js-light';
import { combine, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';

import {
  createOffloadedInterval,
  getState,
  listen,
  modelFactory,
  setState,
} from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { notify } from '@kuna-pay/ui/notification';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';
import { State } from '@kuna-pay/core/shared/lib/state';

import {
  createPaymentMethodSyncSelectModel,
  PaymentMethodAdapter,
} from '@kuna-pay/accept-payment/entities/payment-method';
import { launchDonationProcessForNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Deposit/request/launchDonationProcessForNonAuth';
import { findManyRateForNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Rates/request/findManyRateForNonAuth';
import { i18next } from '@kuna-pay/accept-payment/shared/i18n';

import type {
  FindDetailsForDonationArgs,
  FindDetailsForDonationOutput,
} from './api';
import { FindDetailsForDonationQuery } from './api';
import {
  DonationPageStep,
  DonationPersistence,
  DonationProgress,
} from './model';

const DonationPageModel = modelFactory(() => {
  const PageGate = createGate<{ id: string }>();

  const onSubmit = createEvent();

  const $isSubmitting = createStore(false);

  const $$state = State.factory.createModel<DonationPageStep>(
    DonationPageStep.Loading
  );

  const $$donationQuery = createEQuery({
    initialData: null,
    query: async ({
      companyId,
      addressId,
    }: FindDetailsForDonationArgs): Promise<FindDetailsForDonationOutput> =>
      new FindDetailsForDonationQuery().query({
        companyId,
        addressId: addressId ?? DonationPersistence.getAddressId(),
      }),
  });

  const $$rates = createEQuery({
    initialData: [],
    query: async (_: void) =>
      findManyRateForNonAuth({
        currency: true,
        equivalent: { currency: true, amount: true },
      })(),
  });

  const $$paymentInterval = createOffloadedInterval({
    timeoutMS: 15_000,
  });
  const $$ratesInterval = createOffloadedInterval({
    timeoutMS: 60_000,
  });

  const $$selectPaymentMethod = createPaymentMethodSyncSelectModel({
    getOptionsFx: async () =>
      getState($$donationQuery.$data).then((data) => data!.PaymentMethods),

    valueAdapter: (_, option) =>
      PaymentMethodAdapter.fromPaymentMethodOption(option),
  });

  listen({
    clock: PageGate.open,
    handler: async ({ id }) => {
      try {
        const [{ PaymentMethod, PaymentDetails }] = await Promise.all([
          $$donationQuery
            .startFx({
              companyId: id,
            })
            .catch((error) => {
              if (
                ErrorMatcher.createErrorMatcher('PAYMENT_METHOD_NOT_SUPPORTED')(
                  error
                )
              ) {
                DonationPersistence.clearAddressId();

                return $$donationQuery.startFx({ companyId: id });
              }

              throw error;
            }),

          $$rates.startFx().then(() => {
            $$ratesInterval.start();
          }),
        ]);
        await $$selectPaymentMethod.loadFx();

        if (PaymentMethod) {
          $$selectPaymentMethod.init(
            PaymentMethodAdapter.fromPaymentMethodOption(PaymentMethod)
          );

          $$state.next(DonationPageStep.Initialization);
          $$state.next(
            !isPaymentDetailsEmpty(PaymentDetails)
              ? DonationPageStep.Done
              : DonationPageStep.Payment
          );
        } else {
          $$selectPaymentMethod.init(null);

          $$state.next(DonationPageStep.Initialization);
        }
      } catch {
        notify.warning(i18next.t('pages.donation.requests.load.unknown'));

        $$state.next(DonationPageStep.Failed);
      }
    },
  });

  listen({
    clock: $$ratesInterval.tick,
    handler: () => {
      void $$rates.refreshFx();
    },
  });

  listen({
    clock: onSubmit,
    handler: async () => {
      const { id: companyId } = await getState(PageGate.state);
      invariant.error(companyId, 'Company ID is not selected');

      try {
        const paymentMethod = await getState($$selectPaymentMethod.$value);
        invariant.error(paymentMethod, 'Payment method is not selected');

        setState($isSubmitting, true);

        const result = await launchDonationProcessForNonAuth({
          id: true,
          address: true,
          memo: true,
        })({ companyId, paymentMethodId: paymentMethod.id });

        DonationPersistence.setAddressId(result.id);

        await $$donationQuery.refreshFx({ companyId, addressId: result.id });

        $$state.next(DonationPageStep.Payment);
      } catch (error) {
        if (
          ErrorMatcher.createErrorMatcher('PAYMENT_METHOD_NOT_SUPPORTED')(error)
        ) {
          notify.warning(
            i18next.t('pages.donation.requests.submit.method-not-supported')
          );

          $$selectPaymentMethod.init(null);
          DonationPersistence.clearAddressId();

          await $$donationQuery.refreshFx({ companyId });

          return;
        }

        notify.warning(i18next.t('pages.donation.requests.submit.unknown'));
      } finally {
        setState($isSubmitting, false);
      }
    },
  });

  listen({
    clock: $$state.next,
    handler: (step) => {
      switch (step) {
        case DonationPageStep.Payment: {
          $$paymentInterval.start();

          return;
        }

        case DonationPageStep.Done: {
          $$paymentInterval.stop();
        }
      }
    },
  });

  listen({
    clock: $$paymentInterval.tick,
    handler: async () => {
      const { id: companyId } = await getState(PageGate.state);
      invariant.error(companyId, 'Company ID is not selected');

      try {
        const result = await $$donationQuery.refreshFx({ companyId });

        if (!isPaymentDetailsEmpty(result?.PaymentDetails)) {
          $$state.next(DonationPageStep.Done);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  listen({
    clock: PageGate.close,
    handler: () => {
      $isSubmitting.reinit!();
      $$state.reset();
      $$donationQuery.reset();
      $$rates.reset();
      $$ratesInterval.stop();
      $$paymentInterval.stop();
    },
  });

  return {
    PageGate,

    $$ui: {
      onSubmit,
      $isSubmitting,

      $rate: combine(
        $$rates.$data,
        $$selectPaymentMethod.$value,
        $$donationQuery.$data,
        (rates, selectedPaymentMethod, donation) => {
          const currency =
            donation?.PaymentMethod?.Asset?.code ??
            selectedPaymentMethod?.asset;
          const equivalentCurrency = donation?.EquivalentAsset?.code;

          const rateForCurrency = rates.find(
            (rate) => rate.currency === currency
          );

          if (!rateForCurrency) {
            return null;
          }

          const rateEquivalent = rateForCurrency.equivalent.find(
            ({ currency }) => currency === equivalentCurrency
          );

          if (!rateEquivalent) {
            return null;
          }

          if (new Decimal(rateEquivalent.amount).isZero()) {
            return null;
          }

          return {
            toEquivalentCurrency: new Decimal(rateEquivalent.amount),
            toCurrency: new Decimal(1).div(rateEquivalent.amount),
          };
        }
      ),

      $$rates,
      $$state,
      $$donationQuery,
      $$selectPaymentMethod: $$selectPaymentMethod.$$ui,
      $$progress: {
        $state: combine(
          $$state.$value,
          $isSubmitting,
          $$selectPaymentMethod.$value,
          (state, isSubmitting, selectedPaymentMethod) =>
            DonationProgress.progress(
              state,
              isSubmitting,
              selectedPaymentMethod
            )
        ),
      },
    },
  };
});

function isPaymentDetailsEmpty(
  paymentDetails: FindDetailsForDonationOutput['PaymentDetails']
) {
  if (!paymentDetails) {
    return true;
  }

  return (
    paymentDetails.amount === '0' &&
    paymentDetails.processedAmount === '0' &&
    paymentDetails.fee === '0'
  );
}

export { DonationPageModel };
