import clsx from 'clsx';
import type { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './manage-cookie-list-item.module.scss';

type ManageCookieListItemProps = {
  title: ReactNode;
  description: ReactNode;

  endAdornment?: ReactNode;
};

const ManageCookieListItem = createCompoundComponent(
  (C) =>
    ({ title, description, endAdornment }: ManageCookieListItemProps) => (
      <C.Root>
        <C.Header>
          <C.Title>{title}</C.Title>

          {endAdornment}
        </C.Header>

        <C.Content>{description}</C.Content>
      </C.Root>
    ),
  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Header: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.header, className)} {...props} />
    ),

    Title: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.title, className)}
        variant='subtitle3'
        {...props}
      />
    ),

    Content: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.content, className)}
        variant='body3'
        {...props}
      />
    ),

    AlwaysActive: ({ className, ...props }: Partial<TypographyProps>) => {
      const { t } = useTranslation('core', {
        keyPrefix: 'shared.cookie-consent',
      });

      return (
        <Typography
          className={clsx(styles.alwaysActive, className)}
          variant='subtitle3'
          {...props}
        >
          {t('ui.manage.control.always-active')}
        </Typography>
      );
    },
  }
);

export type { ManageCookieListItemProps };
export { ManageCookieListItem };
