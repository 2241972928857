import type { FC, PropsWithChildren } from 'react';
import React, { createContext, memo, useContext } from 'react';

/**
 * @name `effector-factorio`
 * @see https://github.com/Kelin2025/effector-factorio
 */

/**
 * Creates model `factory`
 * @param creator Function that returns new `model` instance
 * @returns Factory with `createModel` method, `useModel` hook and model `Provider`
 */
const modelFactory = <T extends (...args: any[]) => any>(creator: T) => {
  const ModelContext = createContext<ReturnType<T>['$$ui']>(null);

  const useModel = ({ required = true }: { required?: boolean } = {}) => {
    const model = useContext(ModelContext);

    if (!model && required) {
      throw new Error('No model found');
    }

    return model;
  };

  return {
    /** Function that returns new `model` instance */
    createModel: creator,

    /** Hook that returns current `model.ui` instance */
    useModel,

    /** `Provider` to pass current `model` instance into */
    Provider: ModelContext.Provider,
  };
};

type ModelOf<Factory extends ReturnType<typeof modelFactory>> = ReturnType<
  Factory['createModel']
>;

type ViewModelOf<Factory extends ReturnType<typeof modelFactory>> =
  ModelOf<Factory>['$$ui'];

type ViewModelProps<Factory extends ReturnType<typeof modelFactory>> = {
  $$model: ModelOf<Factory>;
};

/**
 * HOC that wraps your `View` into model `Provider`. Also adds `model` prop that will be passed into `Provider`
 * @param factory Factory that will be passed through Context
 * @param View Root component that will be wrapped into Context
 * @returns Wrapped component
 */
const modelView = <
  Props extends object,
  Factory extends ReturnType<typeof modelFactory>,
>(
  factory: Factory,
  View: Props extends { $$model: any } ? never : FC<Props>
) => {
  const defaultValue = {};

  const Render: FC<Props & ViewModelProps<Factory>> = ({
    $$model,
    ...props
  }) => (
    <factory.Provider value={$$model.$$ui ?? defaultValue}>
      {/* @ts-expect-error 'Omit<Props & ViewModelProps<Factory>, "$$model">' is assignable to the constraint of type 'Props', but 'Props' could be instantiated with a different subtype of constraint '{}'. */}
      <View {...props} />
    </factory.Provider>
  );

  Render.displayName = `ModelView(${View.displayName ?? View.name})`;

  return Render;
};

const pageView = <TModel, Props = {}>(
  factory: {
    createModel: (...args: any[]) => TModel;
    Provider: FC<
      PropsWithChildren & {
        value: any;
      }
    >;
  },
  View: FC<Props>
) => {
  const $$model = factory.createModel();

  const Render = memo((props: Props) => (
    <factory.Provider value={($$model as { $$ui: any }).$$ui}>
      {/* @ts-expect-error 'Omit<Props & ViewModelProps<Factory>, "$$model">' is assignable to the constraint of type 'Props', but 'Props' could be instantiated with a different subtype of constraint '{}'. */}
      <View {...props} />
    </factory.Provider>
  ));

  Render.displayName = `Page(${View.displayName ?? View.name})`;

  return Object.assign(Render as typeof View, { $$model });
};

export type { ModelOf, ViewModelOf, ViewModelProps };
export { modelFactory, modelView, pageView };
