import clsx from 'clsx';
import type { ComponentProps, HTMLElementType } from 'react';
import { createElement } from 'react';

const styled =
  <P extends HTMLElementType>(
    name: P,
    className = '',
    defaultProps: Partial<ComponentProps<P>> = {}
  ) =>
  ({
    className: _className,
    ...props
  }: ComponentProps<P> & { className?: string }) =>
    createElement(name, {
      className: clsx(className, _className),
      ...defaultProps,
      ...props,
    });

export { styled };
