import type { PropsWithChildren } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { formatPercent } from '@kuna-pay/core/shared/lib/percent';

import styles from './risk-score.chart.module.scss';

const RiskScorePieChart = createCompoundComponent(
  (Components) =>
    ({ percent }: { percent: number }) => (
      <Components.Root>
        <Components.Text percent={percent} />

        <Components.SVG>
          <Components.OuterRing />

          <Components.TrackRing percent={percent} />
        </Components.SVG>
      </Components.Root>
    ),
  {
    Root: ({ children }: PropsWithChildren) => (
      <div className={styles.icon}>{children}</div>
    ),

    Text: ({ percent }: { percent: number }) => (
      <Typography className={styles.text} variant='subtitle6'>
        {formatPercent(percent)}
      </Typography>
    ),

    SVG: ({ children }: PropsWithChildren) => (
      <svg width={64} height={64} viewBox='0 0 64 64'>
        {children}
      </svg>
    ),

    OuterRing: () => (
      <circle
        cx='32'
        cy='32'
        r='28'
        stroke='var(--color-black100)'
        fill='transparent'
        strokeWidth={6}
      />
    ),

    TrackRing: ({ percent }: { percent: number }) => (
      <circle
        style={{
          strokeDasharray: `${percent * 1.75} 175`,
          transform: 'rotateZ(-90deg) translateX(-64px)',
        }}
        cx='32'
        cy='32'
        r='28'
        stroke='var(--color-red200)'
        fill='transparent'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    ),
  }
);

export { RiskScorePieChart };
