import {
  SuccessOutput,
  MutationSaveAnswersToQuestionnaireForNonAuthArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const saveAnswersToQuestionnaireForNonAuthRequest = (
  select: Select<SuccessOutput> | string
) => `mutation saveAnswersToQuestionnaireForNonAuth($answers: JSON!, $id: String!) {
  data: saveAnswersToQuestionnaireForNonAuth(answers: $answers, id: $id) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const saveAnswersToQuestionnaireForNonAuth =
  (select: Select<SuccessOutput> | string) =>
  (args: MutationSaveAnswersToQuestionnaireForNonAuthArgs) =>
    query<SuccessOutput>(
      saveAnswersToQuestionnaireForNonAuthRequest(select),
      args
    );
