import { captureException, withScope } from '@sentry/react';
import type { FC, PropsWithChildren } from 'react';
import { useLayoutEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouteError } from 'react-router-dom';

import { AppErrorScreen } from '@kuna-pay/ui/ui/splash-screen';

const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary
    onError={(error, _info) => {
      withScope((scope) => {
        scope.setTag('interface-broken', true);
        captureException(error);
      });
    }}
    fallback={<AppErrorScreen />}
  >
    {children}
  </ErrorBoundary>
);

const RouterErrorBoundary: FC<PropsWithChildren> = () => {
  const error = useRouteError();

  useLayoutEffect(() => {
    if (import.meta.env.DEV) {
      console.error(error);
    }

    withScope((scope) => {
      scope.setTag('interface-broken', true);
      captureException(error);
    });
  }, [error]);

  return <AppErrorScreen />;
};
const withErrorBoundary =
  <T extends Record<string, unknown>>(Component: FC<T>): FC<T> =>
  (props) => (
    <AppErrorBoundary>
      <Component {...props} />
    </AppErrorBoundary>
  );

export { AppErrorBoundary, RouterErrorBoundary, withErrorBoundary };
