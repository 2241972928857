import {
  FindDetailsForDonationPageNonAuthOutput,
  QueryFindDetailsForDonationPageNonAuthArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findDetailsForDonationPageNonAuthRequest = (
  select: Select<FindDetailsForDonationPageNonAuthOutput> | string
) => `query findDetailsForDonationPageNonAuth($companyId: String!) {
  data: findDetailsForDonationPageNonAuth(companyId: $companyId) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'FindDetailsForDonationPageNonAuthOutput')}`;
export const findDetailsForDonationPageNonAuth =
  (select: Select<FindDetailsForDonationPageNonAuthOutput> | string) =>
  (args: QueryFindDetailsForDonationPageNonAuthArgs) =>
    query<FindDetailsForDonationPageNonAuthOutput>(
      findDetailsForDonationPageNonAuthRequest(select),
      args
    );
