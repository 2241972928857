import clsx from 'clsx';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { styled } from '@kuna-pay/ui/theme';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './not-found.module.scss';

const SelectNotFound = createCompoundComponent(
  ({ Title, Subtitle, Root }) =>
    ({ title, subtitle }: { title: string; subtitle?: string }) => (
      <Root>
        <Title>{title}</Title>

        <Subtitle>{subtitle}</Subtitle>
      </Root>
    ),
  {
    Root: styled('div', styles.root),

    Title: forwardRef<HTMLSpanElement, Partial<TypographyProps>>(
      ({ className, ...props }, ref) => (
        <Typography
          ref={ref}
          className={clsx(styles.title, className)}
          variant='numbers1'
          {...props}
        />
      )
    ),

    Subtitle: forwardRef<HTMLSpanElement, Partial<TypographyProps>>(
      ({ className, ...props }, ref) => (
        <Typography
          ref={ref}
          className={clsx(styles.subtitle, className)}
          variant='numbers2'
          {...props}
        />
      )
    ),
  }
);

export { SelectNotFound };
