import {
  LaunchDonationProcessForNonAuthOutput,
  MutationLaunchDonationProcessForNonAuthArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const launchDonationProcessForNonAuthRequest = (
  select: Select<LaunchDonationProcessForNonAuthOutput> | string
) => `mutation launchDonationProcessForNonAuth($companyId: String!, $paymentMethodId: String!) {
  data: launchDonationProcessForNonAuth(
    companyId: $companyId
    paymentMethodId: $paymentMethodId
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'LaunchDonationProcessForNonAuthOutput')}`;
export const launchDonationProcessForNonAuth =
  (select: Select<LaunchDonationProcessForNonAuthOutput> | string) =>
  (args: MutationLaunchDonationProcessForNonAuthArgs) =>
    query<LaunchDonationProcessForNonAuthOutput>(
      launchDonationProcessForNonAuthRequest(select),
      args
    );
