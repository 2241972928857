class PersistStorage {
  private static readonly TEST_ACCESS_KEY = '__persist-test-access-key__';

  public static create(): Storage {
    try {
      localStorage.getItem(PersistStorage.TEST_ACCESS_KEY);
      localStorage.setItem(PersistStorage.TEST_ACCESS_KEY, '');
      localStorage.removeItem(PersistStorage.TEST_ACCESS_KEY);

      return localStorage;
    } catch {
      try {
        sessionStorage.getItem(PersistStorage.TEST_ACCESS_KEY);
        sessionStorage.setItem(PersistStorage.TEST_ACCESS_KEY, '');
        sessionStorage.removeItem(PersistStorage.TEST_ACCESS_KEY);

        return sessionStorage;
      } catch {
        return new HeadlessStorage();
      }
    }
  }
}

class HeadlessStorage implements Storage {
  #kv: Record<string, string> = {};

  public get length(): number {
    return Object.keys(this.#kv).length;
  }

  public getItem(key: string): string | null {
    return this.#kv[key] ?? null;
  }

  /**
   *
   * @note This method is not deterministic, as it depends on the order of keys in the object.
   */
  public key(index: number): string | null {
    return Object.keys(this.#kv)[index] ?? null;
  }

  public removeItem(key: string): void {
    if (key in this.#kv) {
      delete this.#kv[key];
    }
  }

  public setItem(key: string, value: string): void {
    this.#kv[key] = value;
  }

  public clear(): void {
    this.#kv = {};
  }
}

export { PersistStorage };
