import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { ControlInputRootProps } from '@kuna-pay/ui/components/control';
import { Control } from '@kuna-pay/ui/components/control';

import type { InputProps } from '../../input.component';
import { TextField } from '../../input.component';
import type { SearchClearRootProps } from './search.clear.component';
import { SearchClear } from './search.clear.component';
import { SearchStart } from './search.start.component';

type SearchProps = Omit<InputProps, 'color'> &
  Pick<ControlInputRootProps, 'size' | 'variant' | 'color' | 'state'> &
  Pick<SearchClearRootProps, 'onClear'> & {
    classes?: {
      root?: string;
      start?: string;
      clear?: string;
      input?: string;
    };

    styles?: {
      root?: CSSProperties;
      start?: CSSProperties;
      clear?: CSSProperties;
      input?: CSSProperties;
    };
  };

const Search = createCompoundComponent(
  ({ Root, Start, Clear, Content, Input }) =>
    forwardRef<HTMLInputElement, SearchProps>(
      (
        {
          size,
          variant,
          color,
          state,
          disabled,
          onClear,
          classes,
          styles,
          className,
          ...props
        },
        ref
      ) => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        disabled ||= state === 'disabled';

        return (
          <Root
            className={clsx(classes?.root, className)}
            style={styles?.root}
            size={size}
            variant={variant}
            color={color}
            state={state}
          >
            <Start
              className={classes?.start}
              style={styles?.start}
              size={size}
              color={color}
            />

            <Content
              className={classes?.input}
              style={styles?.input}
              size={size}
              hasEndAdornment
              hasStartAdornment
              asChild
            >
              <Input ref={ref} size={size} disabled={disabled} {...props} />
            </Content>

            <Clear
              className={classes?.clear}
              style={styles?.clear}
              size={size}
              onClear={onClear}
              hidden={!props.value || disabled}
            />
          </Root>
        );
      }
    ),
  {
    Root: Control.Input.Root,
    Content: Control.Input.Content,
    Input: TextField.Input,

    Start: SearchStart,

    Clear: SearchClear,
  }
);

export { Search };
export type { SearchProps };
