import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TagProps } from '@kuna-pay/ui/ui/tag';
import { Tag } from '@kuna-pay/ui/ui/tag';
import type { ConvertStatus } from '@kuna-pay/core/generated/public/graphql';

import { AutoConversionStatusMapping } from './status.mappings';
import { AutoConversionStatusText } from './status.text';

type AutoConversionStatusTagProps = Partial<Omit<TagProps, 'children'>> & {
  status: ConvertStatus;
};
const AutoConversionStatusTag = createCompoundComponent(
  (Components) =>
    ({ status, ...props }: AutoConversionStatusTagProps) => (
      <Components.Root status={status} {...props}>
        <Components.Text status={status} />
      </Components.Root>
    ),
  {
    Root: ({
      status,
      ...props
    }: Partial<TagProps> & { status: ConvertStatus }) => (
      <Tag
        variant={AutoConversionStatusMapping.tagVariant[status]}
        {...props}
      />
    ),

    Text: AutoConversionStatusText,
  }
);

export { AutoConversionStatusTag };
export type { AutoConversionStatusTagProps };
