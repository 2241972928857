import { memo } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { NumericFormatProps } from 'react-number-format/types/types';

import type { TextFieldProps } from '@kuna-pay/ui/ui/text-field';
import { TextField } from '@kuna-pay/ui/ui/text-field';

import type { FieldModel, MappedField } from '../lib';
import { useField } from '../lib';

type FormNumberFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'onBlur' | 'error' | 'value' | 'type' | 'defaultValue'
> &
  Pick<
    NumericFormatProps,
    | 'suffix'
    | 'prefix'
    | 'allowLeadingZeros'
    | 'allowNegative'
    | 'isAllowed'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'type'
  > & {
    field: FieldModel<number> | FieldModel<string | null>;
    hideValidation?: boolean;

    format?: (value: NumberFormatValues) => string;
  };

const FormNumberField = memo(
  ({
    hideValidation,
    format,

    field,
    disabled,
    helperText,
    ...props
  }: FormNumberFieldProps) => {
    const {
      path,
      value,
      onChange,
      onFocus,
      onBlur,

      error: fieldError,
      shouldShowValidation: fieldShouldShowValidation,
      disabled: formDisabled,
    } = useField(
      field as unknown as FieldModel<number>
      //Bcs number must be handled as string
    ) as unknown as MappedField<string>;

    const error =
      !hideValidation && fieldShouldShowValidation ? fieldError : undefined;

    return (
      <NumericFormat
        name={path}
        value={value}
        onValueChange={(value) => {
          if (format) {
            onChange(format(value));

            return;
          }
          onChange(value.value);
        }}
        onFocus={onFocus}
        onBlur={() => {
          try {
            // To fix all issues with formats
            // Assumed: user cannot click submit white input is focused
            onChange(`${parseFloat(value)}`);
          } catch {
            //pass
          }

          onBlur();
        }}
        isError={!!error}
        /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
        helperText={error || helperText}
        disabled={!!disabled || !!formDisabled}
        allowLeadingZeros
        valueIsNumericString
        {...props}
        customInput={TextField}
      />
    );
  }
);

export { FormNumberField };
export type { FormNumberFieldProps };
