import type { Store } from 'effector';
import { combine, createStore } from 'effector';
import { not } from 'patronum';

import { UNIT_SHAPE } from '@kuna-pay/utils/effector';

const createFeatureFlagFactory = <TAvailableFeatureFlags extends string>(
  $features: Store<Record<string, boolean>>
) => {
  /**
   * When this flag is disabled, all other flags will be disabled too
   * If flag is enabled, child flags will be enabled/disabled based on their own state
   *
   * @default Store<true>
   *
   * @example
   * ```
   * const $$scopedFeatureFlag =
   *    createFeatureFlag("IS_SCOPED_FEATURE_ENABLED")
   *        .dependsOn("IS_SCOPE_ENABLED");
   * ```
   */
  function createDependentFeatureFlagFactory(
    $parentEnabled: Store<boolean> = createStore(true, {
      name: '$enabled',
    })
  ) {
    return function factory(name: TAvailableFeatureFlags, override?: boolean) {
      const $enabled = combine($features, (kv) => {
        if (override !== undefined) {
          return override;
        }

        return !!kv[name];
      });

      const $resultEnabled = combine(
        $parentEnabled,
        $enabled,
        (parentEnabled, enabled) => {
          if (!parentEnabled) return false;

          return enabled;
        }
      );
      const $resultDisabled = not($resultEnabled);

      return {
        name,
        $enabled: $resultEnabled,
        $disabled: $resultDisabled,

        [UNIT_SHAPE]: () => ({
          enabled: $resultEnabled,
          disabled: $resultDisabled,
        }),

        dependsOn: createDependentFeatureFlagFactory($resultEnabled),
      };
    };
  }

  const factory = createDependentFeatureFlagFactory();

  return factory;
};

export { createFeatureFlagFactory };
