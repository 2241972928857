import type { EventCallable } from 'effector';
import { combine, createEvent, createStore } from 'effector';

import { bridge } from '@kuna-pay/utils/misc';

import type { CreateFormOptions, FormModel } from './types';
import type { MappedField } from './use-field';
import { useField } from './use-field';
import type { MappedErrors } from './yup';

const useFormError = <V>(form: FormModel<V>): MappedField<V>['error'] =>
  useField((form as FormModel<V & { formError: string }>).fields.formError)
    .error;

const createFormError = () => {
  const setError = createEvent<string>();

  const $errors = createStore<MappedErrors<{ formError: string }>>({});

  bridge(() => {
    $errors.on(setError, (_, error) => ({ formError: error }));
  });

  const bindForm = <V>(form: FormModel<V>) => {
    $errors.reset(form.changed).reset(form.reinit).reset(form.reset);

    return form;
  };

  return { $errors, bindForm, setFormError: setError };
};

const withFormError =
  (factory: <Inner>(config: CreateFormOptions<Inner>) => FormModel<Inner>) =>
  <Outer>(
    config: CreateFormOptions<Outer>
  ): FormModel<Outer> & {
    setFormError: EventCallable<string>;
  } => {
    const $$formError = createFormError();

    const $errors = (
      config.$errors
        ? combine(config.$errors, $$formError.$errors, (base, inner) => ({
            ...base,
            ...inner,
          }))
        : $$formError.$errors
    ) as CreateFormOptions<Outer>['$errors'];

    const form = factory({ ...config, $errors });

    $$formError.bindForm(form);

    return {
      ...form,
      setFormError: $$formError.setFormError,
    };
  };

export { createFormError, useFormError, withFormError };
