import { combine, createEvent, createStore } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

import type { ValueState } from './value.declaration';

const createSingleValueModel = (config: { value?: string | null } = {}) => {
  const change = createEvent<string>();
  const reset = createEvent();

  const $value = createStore<string | null>(config.value ?? null);

  const { $focused, blur, focus } = atom(() => {
    const focus = createEvent();
    const blur = createEvent();

    const $focused = createStore(false)
      .on(focus, () => true)
      .on(blur, () => false);

    return {
      $focused,
      focus,
      blur,
    };
  });

  $value.on(change, (_, payload) => payload).reset(reset);

  return {
    /**
     * To trigger close if clicked on selected option
     */
    changed: change,
    $value,

    focus,
    blur,
    reset,

    $$ui: {
      $value,
      $focused,
      change,

      $state: combine(
        $value,
        (value): ValueState => (value ? 'filled' : 'empty')
      ),
    },

    __: {},
  };
};

export { createSingleValueModel };
