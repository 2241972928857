import type { TagVariant } from '@kuna-pay/ui/ui/tag';
import { TransferStatus } from '@kuna-pay/core/generated/public/graphql';

class TransactionsStatusMapping {
  public static text: Record<TransferStatus, string> = {
    [TransferStatus.Created]: 'created',
    [TransferStatus.Canceled]: 'cancelled',
    [TransferStatus.Processing]: 'processing',
    [TransferStatus.PartiallyProcessed]: 'partially-processed',
    [TransferStatus.Processed]: 'processed',
    [TransferStatus.Suspended]: 'suspended',
    [TransferStatus.Arrested]: 'arrested',
    [TransferStatus.ActionRequired]: 'action-required',
  };

  public static tagVariant: Record<TransferStatus, TagVariant> = {
    [TransferStatus.Created]: 'warning',
    [TransferStatus.Processing]: 'warning',
    [TransferStatus.Processed]: 'success',
    [TransferStatus.PartiallyProcessed]: 'danger',
    [TransferStatus.Arrested]: 'danger',
    [TransferStatus.Suspended]: 'danger',
    [TransferStatus.Canceled]: 'danger',
    [TransferStatus.ActionRequired]: 'warning',
  };

  public static color: Record<TransferStatus, string> = {
    [TransferStatus.Created]: 'var(--color-yellow600)',
    [TransferStatus.Processing]: 'var(--color-yellow600)',
    [TransferStatus.Processed]: 'var(--color-green500)',
    [TransferStatus.PartiallyProcessed]: 'var(--color-red500)',
    [TransferStatus.Arrested]: 'var(--color-red500)',
    [TransferStatus.Suspended]: 'var(--color-red500)',
    [TransferStatus.Canceled]: 'var(--color-red500)',
    [TransferStatus.ActionRequired]: 'var(--color-yellow600)',
  };
}

export { TransactionsStatusMapping };
