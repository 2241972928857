import type { Event, EventCallable, Store } from 'effector';
import { combine, createEvent, createStore } from 'effector';

import { atom } from '@kuna-pay/utils/misc';
import { trimPayload } from '@kuna-pay/utils/transform';

import { ASYNC_LOADER_DEFAULT_TAKE } from './loader.async.config';
import type {
  AsyncLoaderDone,
  AsyncLoaderFail,
  AsyncLoaderParams,
} from './loader.async.declaration';
import { AsyncLoaderOperation } from './loader.async.declaration';
import type { OptionsLoader } from './loader.declaration';
import { stringFilter } from './loader.lib';

type AsyncLoaderConfig = {
  $$load: {
    start: EventCallable<AsyncLoaderParams>;

    /**
     * @note Loader should pass `operation:AsyncLoaderOperation` from `start` event
     */
    done: Event<AsyncLoaderDone>;

    fail: Event<AsyncLoaderFail>;
  };

  $$search?: {
    sync: true;
    filter?: (options: string[], query: string) => string[];
  };

  take?: number;

  disableLoadMore?: boolean;
};

type AsyncLoaderSearchConfig =
  | { sync: true; filter: (options: string[], query: string) => string[] }
  | { sync: false };

const createAsyncLoader = (config: AsyncLoaderConfig): OptionsLoader => {
  const take = config.take ?? ASYNC_LOADER_DEFAULT_TAKE;

  const $$search: AsyncLoaderSearchConfig = !!config.$$search?.sync
    ? { sync: true, filter: config.$$search.filter ?? stringFilter }
    : { sync: false };

  const reset = createEvent();

  const $pending = createStore<Record<AsyncLoaderOperation, boolean>>({
    [AsyncLoaderOperation.Load]: false,
    [AsyncLoaderOperation.LoadMore]: false,
  })
    .on(config.$$load.start, (state, { operation }) => ({
      ...state,
      [operation]: true,
    }))
    .on([config.$$load.done, config.$$load.fail], (state, { operation }) => ({
      ...state,
      [operation]: false,
    }));

  return {
    $$load: atom(() => {
      const done = filterMapResult(
        config.$$load.done,
        AsyncLoaderOperation.Load
      );

      return {
        start: config.$$load.start.prepend(() => ({
          params: {
            skip: 0,
            take,
            search: '',
          },

          operation: AsyncLoaderOperation.Load,
        })),

        search: config.$$load.start.prepend(({ query }: { query: string }) => ({
          params: {
            skip: 0,
            take,
            search: query,
          },

          operation: AsyncLoaderOperation.Load,
        })),

        $pending: filterPending($pending, AsyncLoaderOperation.Load),

        done: done,

        fail: filterByOperation(
          config.$$load.fail,
          AsyncLoaderOperation.Load
        ).map(trimPayload),
      };
    }),

    $$loadMore: atom(() => {
      const syncStart = createEvent<{ skip: number; search: string }>();
      const asyncStart = config.$$load.start.prepend(
        ({ skip, search }: { skip: number; search: string }) => ({
          params: {
            skip,
            take,
            search,
          },

          operation: AsyncLoaderOperation.LoadMore,
        })
      );

      const filteredDone = filterMapResult(
        config.$$load.done,
        AsyncLoaderOperation.LoadMore
      );

      const done = filteredDone.map((options) => ({
        newOptions: options,
        hasMore: !config.disableLoadMore ? options.length !== 0 : false,
      }));

      return {
        start: !config.disableLoadMore ? asyncStart : syncStart,

        done: !config.disableLoadMore
          ? done
          : syncStart.map(() => ({
              newOptions: [] as string[],
              hasMore: false as boolean,
            })),

        $pending: filterPending($pending, AsyncLoaderOperation.LoadMore),

        fail: filterByOperation(
          config.$$load.fail,
          AsyncLoaderOperation.LoadMore
        ).map(trimPayload),
      };
    }),

    $$search,

    reset,
  };
};

function filterPending(
  store: Store<Record<AsyncLoaderOperation, boolean>>,
  operation: AsyncLoaderOperation
) {
  return combine(store, (state) => state[operation]);
}

function filterByOperation<T>(
  event: Event<T & { operation: AsyncLoaderOperation }>,
  operation: AsyncLoaderOperation
) {
  return event.filter({ fn: ({ operation: op }) => op === operation });
}

function filterMapResult<T>(
  event: Event<{ result: T; operation: AsyncLoaderOperation }>,
  operation: AsyncLoaderOperation
) {
  return filterByOperation(event, operation).map(({ result }) => result);
}

export { AsyncLoaderOperation, createAsyncLoader };
export type { AsyncLoaderConfig };
