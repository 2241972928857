import type { Store, StoreWritable } from 'effector';
import { is as effectorIs } from 'effector';

const is = {
  store<T>(maybeStore: T | Store<T>): maybeStore is Store<T> {
    return effectorIs.store(maybeStore);
  },

  storeWritable<T>(
    store: Store<T> | StoreWritable<T>
  ): store is StoreWritable<T> {
    return effectorIs.targetable(store);
  },
};

export { is };
