import { combine, createEvent, sample } from 'effector';
import { delay } from 'patronum';

import { listen, modelFactory } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import type { FieldModel, FormModel } from '@kuna-pay/form';

import type { OptionsLoader } from './loader';
import {
  createAsyncLoader,
  createOnceLoader,
  createSyncLoader,
} from './loader';
import { SelectOptionsFactory } from './options.model';
import { SelectRootFactory } from './root.model';
import { SelectSearchFactory } from './search.model';
import type { ExternalSingleValueSource } from './value';
import { createExternalSingleValue, createSingleValueModel } from './value';

type SingleSelectModelConfig = {
  $$loader: OptionsLoader;

  $$value?: ExternalSingleValueSource;

  /**
   * Works only for internal value model
   */
  defaultValue?: string;

  options?: {
    disableCloseOnSelect?: boolean;
  };
};

const SingleSelectModel = Object.assign(
  modelFactory(
    ({
      $$loader,
      options = { disableCloseOnSelect: false },
      ...config
    }: SingleSelectModelConfig) => {
      const reset = createEvent();

      const $$root = SelectRootFactory.createModel();

      const $$search = SelectSearchFactory.createModel();

      const $$options = SelectOptionsFactory.createModel({
        $$loader,
        $searchQuery: $$search.$query,
      });

      const $$value = config.$$value
        ? createExternalSingleValue({ $$value: config.$$value })
        : createSingleValueModel({ value: config.defaultValue });

      if (!options?.disableCloseOnSelect) {
        listen({
          clock: $$value.changed,
          handler: () => {
            $$root.close();
          },
        });
      }

      atom(() => {
        sample({
          clock: $$root.opened,
          target: $$value.focus,
        });

        sample({
          clock: $$root.closed,
          target: $$value.blur,
        });
      });

      atom(() => {
        sample({
          clock: $$root.closed,
          target: $$search.clear,
        });
      });

      atom(() => {
        sample({
          clock: reset,
          target: [
            $$search.reset,
            $$options.reset,
            $$root.reset,
            $$loader.reset,
            $$value.reset,
          ],
        });
      });

      return {
        $$value,

        $$load: createOnceLoader($$loader),

        changed: delay({ source: $$value.changed, timeout: 250 }),
        reset,

        $$ui: {
          $$root: $$root,
          $$search: $$search,
          $$options: $$options,
          $$value: $$value.$$ui,

          $$state: {
            $options: $$options.$state,
          },
        },

        __: {
          $$root: $$root.__,
          $$search: $$search.__,
          $$options: $$options.__,
        },
      };
    }
  ),
  {
    Value: {
      fromField: function fromSingleField<T = string | null | undefined>(
        field: Pick<FieldModel<T>, '$value' | 'change' | 'path'>,
        $$form: Pick<FormModel<any>, 'focused' | 'blured'>
      ): ExternalSingleValueSource {
        const focus = createEvent();
        const blur = createEvent();

        listen({
          clock: focus,
          handler: () => {
            $$form.focused([field.path]);
          },
        });

        listen({
          clock: blur,
          handler: () => {
            $$form.blured([field.path]);
          },
        });

        return {
          $value: combine(
            field.$value,
            (value) => (value ?? null) as string | null
          ),
          change: field.change.prepend((value) => value as T),

          focus,
          blur,
        };
      },

      createSingleValueModel,
    },

    Loader: {
      createSyncLoader,

      createAsyncLoader,
    },
  }
);

export { SingleSelectModel };
