import type { Effect } from 'effector';

import type { EffectFailPayload } from '@kuna-pay/utils/effector/effect';

class ErrorMatcher {
  public static fromEffectPayload =
    (code: string | string[]) =>
    <Fx extends Effect<any, any>>({ error }: EffectFailPayload<Fx>) =>
      ErrorMatcher.createErrorMatcher(code)(error);

  public static getApiErrorMessage = (error: APIException): string =>
    error?.[0]?.message;

  public static getApiErrorCode = (error: unknown): string =>
    //@ts-expect-error missing typings
    error?.[0]?.code;

  public static isAPIException = (error: unknown): error is APIException => {
    try {
      if (!Array.isArray(error)) return false;

      const [firstError] = error;

      if (!firstError) return false;

      return 'code' in firstError && 'message' in firstError;
    } catch {
      return false;
    }
  };

  // TODO: import API error codes from backend
  public static createErrorMatcher =
    (code: string | string[]) =>
    (error: unknown): error is APIException => {
      const errorCode = ErrorMatcher.getApiErrorCode(error);

      return Array.isArray(code)
        ? code.some((code) => code === errorCode)
        : code === errorCode;
    };

  public static isAPIIdempotencyGuardError(error: unknown) {
    if (!ErrorMatcher.isAPIException(error)) return false;

    const message = ErrorMatcher.getApiErrorMessage(error);

    if (!message) return false;

    return message.includes('Idempotency guard stop method');
  }

  public static getApiErrorFields = <T>(
    error: APIException<T>
  ): Record<string, string> | undefined => error?.[0]?.fields;

  public static markAsHandled = (error: unknown): void => {
    if (!ErrorMatcher.isAPIException(error)) return;

    error.handled = true;
  };

  public static isMarkedAsHandled = (error: unknown): boolean => {
    if (!ErrorMatcher.isAPIException(error)) return false;

    return !!error?.handled;
  };
}

type APIException<T = string> = [
  { code: T; message: string; fields?: Record<string, string> },
] & {
  handled?: boolean;
};

export type { APIException };
export { ErrorMatcher };
