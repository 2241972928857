import clsx from 'clsx';
import type { ComponentPropsWithoutRef, HTMLAttributes } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import {
  StepperIconActive,
  StepperIconCompleted,
  StepperIconInactive,
  StepperIconPending,
} from './assets';
import styles from './horizontal-stepper.module.scss';

type StepState = 'inactive' | 'active' | 'pending' | 'completed';
type StepAlign = 'start' | 'center' | 'end';

const HorizontalStepper = {
  Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.root, className)} {...props} />
  ),

  Step: {
    Root: ({
      className,
      children,
      state,
      align,
      ...props
    }: HTMLAttributes<HTMLDivElement> & {
      state: StepState;
      align: StepAlign;
    }) => {
      const value = useMemo(() => ({ state, align }), [state, align]);

      return (
        <StepContext.Provider value={value}>
          <div
            className={clsx(
              styles.item,
              {
                [styles.initial]: state === 'inactive',
                [styles.active]: state === 'active',
                [styles.pending]: state === 'pending',
                [styles.completed]: state === 'completed',
              },
              {
                [styles.start]: align === 'start',
                [styles.center]: align === 'center',
                [styles.end]: align === 'end',
              },

              className
            )}
            {...props}
          >
            {children}
          </div>
        </StepContext.Provider>
      );
    },

    Icon: ({
      state,
      className,
      ...props
    }: ComponentPropsWithoutRef<typeof StepperIconActive> & {
      state?: StepState;
    }) => {
      const context = useContext(StepContext);

      state ??= context.state;

      const Icon = {
        inactive: StepperIconInactive,
        active: StepperIconActive,
        pending: StepperIconPending,
        completed: StepperIconCompleted,
      }[state];

      return (
        <Icon
          className={clsx(
            styles.icon,
            {
              [styles.initial]: state === 'inactive',
              [styles.active]: state === 'active',
              [styles.pending]: state === 'pending',
              [styles.completed]: state === 'completed',
            },
            className
          )}
          {...props}
        />
      );
    },

    Connector: createCompoundComponent(
      (Components) =>
        ({
          state,
          align,
          nextStepAlign,
        }: {
          state?: StepState;
          align?: StepAlign;
          nextStepAlign: StepAlign;
        }) => (
          <Components.Root>
            <Components.Line
              state={state}
              align={align}
              nextStepAlign={nextStepAlign}
            />
          </Components.Root>
        ),
      {
        Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
          <div className={clsx(styles.connector, className)} {...props} />
        ),

        Line: ({
          className,
          state,
          align,
          nextStepAlign,
          ...props
        }: HTMLAttributes<HTMLDivElement> & {
          state?: StepState;
          align?: StepAlign;
          nextStepAlign: StepAlign;
        }) => {
          const context = useContext(StepContext);

          state ??= context.state;
          align ??= context.align;

          return (
            <div
              className={clsx(
                styles.line,
                {
                  [styles.initial]: state === 'inactive',
                  [styles.active]: state === 'active',
                  [styles.pending]: state === 'pending',
                  [styles.completed]: state === 'completed',
                },
                {
                  [styles.start]: align === 'start',
                  [styles.center]: align === 'center',
                  [styles.end]: align === 'end',
                },
                {
                  [styles.nextStepAlignStart]: nextStepAlign === 'start',
                  [styles.nextStepAlignCenter]: nextStepAlign === 'center',
                  [styles.nextStepAlignEnd]: nextStepAlign === 'end',
                },
                className
              )}
              {...props}
            />
          );
        },
      }
    ),

    Label: ({
      className,
      state,
      ...props
    }: Partial<TypographyProps> & {
      state?: StepState;
    }) => {
      const context = useContext(StepContext);

      state ??= context.state;

      const isActive = state === 'active' || state === 'pending';

      return (
        <Typography
          variant={isActive ? 'subtitle6' : 'numbers1'}
          className={clsx(
            styles.label,
            { [styles.active]: isActive },
            className
          )}
          {...props}
        />
      );
    },
  },
};

const StepContext = createContext<{ state: StepState; align: StepAlign }>(
  null as never
);

export { HorizontalStepper };
export type { StepState };
