import {
  PreRequestInvoiceOutput,
  MutationPreRequestPublicInvoiceArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const preRequestPublicInvoiceRequest = (
  select: Select<PreRequestInvoiceOutput> | string
) => `mutation preRequestPublicInvoice($id: String!, $paymentAsset: String!, $paymentMethodCode: String!) {
  data: preRequestPublicInvoice(
    id: $id
    paymentAsset: $paymentAsset
    paymentMethodCode: $paymentMethodCode
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'PreRequestInvoiceOutput')}`;
export const preRequestPublicInvoice =
  (select: Select<PreRequestInvoiceOutput> | string) =>
  (args: MutationPreRequestPublicInvoiceArgs) =>
    query<PreRequestInvoiceOutput>(
      preRequestPublicInvoiceRequest(select),
      args
    );
